import React from 'react'
import "./About.scss";

import { motion } from 'framer-motion';
import { bios } from '../../../Data';
import portfolio from "../../../assets/portfolio.jpg"
import { HiOutlineCloudDownload } from "react-icons/hi";
import { FaRegHandPointDown,FaPhoneAlt , FaPaperPlane } from "react-icons/fa"


const cv = 'https://davidvallejo.co/David_Vallejo_Resume.pdf';
const About = () => {

const downloadResume=(url)=>{
const fileName = url.split('/').pop();
const aTag = document.createElement('a');
aTag.href=url;
aTag.setAttribute('download', fileName);
document.body.appendChild(aTag);
aTag.click();
aTag.remove();
}
  return (
    <div className="container " id='about'>
      <div
        className="title">
        <span>Intro</span>
        <h1>About Me:</h1>
      </div>

      <div className="about_container">
        <motion.div 
          initial={{ x: 0, opacity: 0 }}
          whileInView={{ x: [-250, 0], opacity: 1 }}
          transition={{ duration: 1 }}
        >

<div className="scrollable-text-box" > 

      <p>
         Hello! My name is David Vallejo, I am an expert web designer & web developer who is dedicated to excellence. I have a love for learning, coding and nature. I currently hold over seven years of experience in web design, web development, and UX/UI design, I've had the pleasure of working on a variety of projects that have allowed me to grow and evolve as a developer.
         <br></br>
         I went to school at the University of Central Florida where I got my degree in Digital Media: Web Design, and honed my tech and design skills from some of the best in the world. <br></br>
        My favorite things include traveling and experiencing new cultures, spending time with my family, and playing with my 2 year old cocker spaniel Daisy.<br></br>
        My goals are to help others further their own ambitions and goals using my tech skills, while learning, developing, and building in new and current technologies 
      </p>

    </div>

    <a class="contact-link" href="tel:813-326-3626"><FaPhoneAlt/>&nbsp;&nbsp;Phone:&nbsp; 813-326-3626</a>
     <br></br>
     <a class="contact-link" href="mailto:david@davidvallejo.co"><FaPaperPlane/>&nbsp;&nbsp;Email:&nbsp;david@davidvallejo.co</a>
  
          
          <motion.a onClick={()=>{downloadResume(cv)}}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.3 }}
            className="download"
          >DOWNLOAD MY CV
          <HiOutlineCloudDownload /> 
          </motion.a>
          
        </motion.div>

      </div>

    </div>
  )
}

export default About