import React, { useEffect, useState } from 'react';
import "./Portfolio.scss"
import { workNavs } from "../../../Data";
import { workImages } from '../../../Data';
import {FiGithub, FiEye} from "react-icons/fi"
import { motion, AnimatePresence } from 'framer-motion';

const Portfolio = () => {
  const [tab, setTab] = useState({ name: "all" });
  const [works,setWorks] = useState([])
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (tab.name === "all") {
      setWorks(workImages)
    } else {
      const newWork = workImages.filter(workImage => {
        return workImage.category.toLowerCase() === tab.name;

      })
      setWorks(newWork)
    }
  }, [tab])
  
  const activeTab = (e,index) => {
    setTab({ name: e.target.textContent.toLowerCase() });
    setActive(index)
  }

  return (
    <div className="container" id="portfolio">
      <div

        className="title"
      
      >
            <span>See what I've been working on</span>
            <h1>Projects</h1>
      </div>
      <div
        className="buttons"
      >
        {workNavs.map((workNav ,index) => {
          return (
            <button
              onClick={(e) => activeTab(e, index)}
              className={`${active === index ? "active" : "All"}`}
              key={index}>{workNav}</button>
          )
        })}
      </div>
      <div
        className="workImages"
      >
        <AnimatePresence>
        {works.map((work) => {
          return (
            <motion.div layout  className="workImage"
             key={work.id}
            >
              <img src={work.img} alt="workImg" />
              <motion.div
                initial={{opacity: 0}}
                whileHover={{ opacity: [0, 1] }}
                transition={{duration: 0.3 , ease: "easeInOut"}}
                className='hoverLayer'
              >
                { work.github !== "#" ?
                <motion.a 
                href={work.github}
                target="_blank"
                rel="noreferrer noopener"
                whileInView={{scale: [0,1]}}
                 whileHover={{scale: [1, 1.1]}}
                 transition={{duration: 0.3}}
                >
                  <FiGithub />
                </motion.a> : null
        }
       
       { work.a !== "#" ?   
                <motion.a 
                href={work.a}
                target="_blank"
                rel="noreferrer noopener"
                whileInView={{scale: [0,1]}}
                 whileHover={{scale: [1, 1.1]}}
                 transition={{duration: 0.3}}
                >
                  <FiEye />
                </motion.a> : null
        }
              </motion.div>
            </motion.div>
          )
        })}</AnimatePresence>

      </div>

      
    </div>
  )
}

export default Portfolio