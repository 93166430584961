import { FaHtml5, FaCss3, FaNodeJs, FaJs, FaReact, FaSass, FaFigma, FaPhoneAlt, FaUser, FaMapMarkerAlt, FaPaperPlane,FaElementor, FaWordpress, FaInstagram, FaLinkedin } from "react-icons/fa";
import { DiCss3 } from "react-icons/di";
import { SiTypescript, SiPhp, SiMongodb, SiMysql, SiExpress } from "react-icons/si";
import { GrGraphQl } from "react-icons/gr"
import { BsMedium } from "react-icons/bs";
import { FiCodepen } from "react-icons/fi";
import {TbBrandReactNative} from "react-icons/tb"
import portfolio from "./assets/portfolio.jpg";
import work1 from "./assets/work3.png";
import work2 from "./assets/youtube-api.png";
import work3 from "./assets/work4.png";
import work4 from "./assets/work1.png";
import work5 from "./assets/work5.png";
import work6 from "./assets/work6.png";
import { Link } from 'react-router-dom';

export const navLinks = ["home", "about", "skills", "portfolio", "contact"]

export const socialIcons = [
  <a href="https://www.linkedin.com/in/david-vallejo-004889139/">
    <FaLinkedin />
  </a>,
  <a href="https://codepen.io/david-vallejo">
    <FiCodepen />
  </a>
]

export const bios = [
  {
    id: 1,
    icon: <FaPhoneAlt />,
    key: "Phone:",
    value: "813-326-3626"
  },
  {
    id: 2,
    icon: <FaPaperPlane />,
    key: "Email: ",
    value: 'david@davidvallejo.co'
  }
]

export const icons = [{
  icon: <FaHtml5 />,
  name: "HTML"
}, 
{icon: <DiCss3 />,
name: "CSS"}, 
{icon: <FaJs />,
name: "JavaScript"}, 
{icon: <SiPhp />,
name: "PHP"}, 
{icon: <FaReact />,
name: "React"}, 
{icon: <FaNodeJs />,
name: "NodeJS"}, 
{icon: <FaSass />,
name: "Sass"}, 
{icon: <FaFigma />,
name: "Figma"}, 
{icon: <SiMongodb/>,
name: "MongoDB"}, 
{icon: <SiMysql/>,
name: "MySQL"}, 
{icon: <FaElementor/>,
name: "Elementor"},
{icon: <FaWordpress/>,
name: "Wordpress"}]

export const experiences = [
  {
    id: 1,
    year: "2022",
    position: "Integration Engineer",
    company: "Bluefin Payments",
    desc: "Provided world-class customer service answering phones part of the day helping with technical issues and guiding clients through troubleshooting. Creating integrations and assisting with current integrations to our software and APIs to businesses and merchants. Created documentation for integrations and technical processes with the software. Worked with payment devices and learned many new technologies to assist with the smooth use of our products, software, and APIs. Coded and developed multiple software and API proof of concepts for assistance in contacting software engineers, and for working examples."
  },
  {
    di: 2,
    year: "2021",
    position: "Web Designer",
    company: "davidvallejo.co",
    desc: "As a web designer, I was responsible for creating visually appealing, user-friendly websites for clients across various industries. My primary responsibilities included designing and developing websites from scratch, customizing pre-existing templates, and optimizing websites for search engines. Additionally, I worked closely with clients to understand their business objectives and translate them into website design and functionality. I also provided ongoing website maintenance, troubleshooting, and technical support to clients. As a remote worker, I effectively communicated with clients, managed projects, and delivered high-quality work on time. I also remained up-to-date with industry trends and technologies to provide cutting-edge website design and development services"
  },
  {
    id: 3,
    year: "2020",
    position: "Web Designer",
    company: "Progressive Dental",
    desc: "As a Web Designer at Progressive Dental, my primary responsibility was to design and develop visually compelling and functional websites for dental practices across the country. I worked closely with clients, project managers, and other designers to understand the client's vision and translate it into a website design that effectively conveyed their brand and services. In addition to website design and development, I also managed website updates, performed website maintenance and testing, and provided technical support to clients. Furthermore, I collaborated with marketing teams to ensure that the website design aligned with marketing campaigns and objectives. As a Web Designer at Progressive Dental, I stayed current with the latest web design and development technologies to ensure the websites were both visually appealing and functionally efficient."
  }
]
export const workImages = [

 
  {
    id: 1,
    img: work2,
    name: "Youtube API Dark UI App",
    category: "React",
    github: "https://github.com/david-vallejo/Youtube-RapidAPI-React",
    a: "https://youtube-ui-clone-273b80.netlify.app/"
  },
  {
    id: 2,
   img: work3,
   name: "Findbuyhappy.com - E-commerce store made with Elementor and Woo-Commerce",
   category: "Wordpress",
  github: "#",
   a: "https://findbuyhappy.com"
  },
  {
    id: 3,
    img: work1,
    name: "Workout App",
    category: "React",
    github: "https://github.com/david-vallejo/Workout-app",
    a: "https://workouts-application-4cd2c2.netlify.app/"
  },
  {
   id: 4,
   img: work4,
   name: "Non-Profit website made under bizcentral.usa",
   category: "Wordpress",
  github: "#",
   a: "https://africandiasporagroup.org/"
  },
  {
    id: 5,
    img: work5,
    name: "Bows for Veterans - a website made for a non-profit org with wordpress and elementor",
    category: 'Wordpress',
    github: "#",
    a: "http://bowsforveterans.org"
  },
  {
    id: 6,
    img: work6,
    name: "Larsinc - a website made for a non profit using wordpress and elementor",
    category: "Wordpress",
    github: "#",
    a: "http://larsinc.org"
  },
  // {
  //   id: 7,
  //   img: work5,
  //   name: "project 7",
  //   category: 'app',
  //   github: "#",
  //   a: "#"
  // },
  // {
  //   id: 8,
  //   img: work6,
  //   name: "project 8",
  //   category: "design",
  //   github: "#",
  //   a: "#"
  // },
  // {
  //   id: 9,
  //   img: work5,
  //   name: "project 9",
  //   category: 'app',
  //   github: "#",
  //   a: "#"
  // },
  // {
  //   id: 10,
  //   img: work6,
  //   name: "project 10",
  //   category: "design",
  //   github: "#",
  //   a: "#"
  // },
  // {
  //   id: 11,
  //   img: work5,
  //   name: "project 11",
  //   category: 'app',
  //   github: "#",
  //   a: "#"
  // },
  // {
  //   id: 12,
  //   img: work6,
  //   name: "project 12",
  //   category: "design",
  //   github: "#",
  //   a: "#"
  // }
  
]


export const workNavs = [
  "All", "React", "Wordpress"
]

export const contacts = [
  {
    id: 1,
    icon: <FaMapMarkerAlt />,
    infoText: "Florida"
  },
  {
    id: 2,
    icon: <FaPaperPlane />,
    infoText: "  david@davidvallejo.co"
  },
  {
    id: 3,
    icon: <FaPhoneAlt />,
    infoText: "  813-326-3626"
  }
]