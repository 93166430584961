import React, { useState } from 'react'
import "./Skills.scss";
import { icons } from '../../../Data';
import { experiences } from '../../../Data';
import { finishes } from '../../../Data';
import { motion } from 'framer-motion';



const Skills = () => {
  const [active, setActive] = useState(1)
  return (
    <div className="container" id="skills">

      <div
        className="title"
      >
        <span>I have a particular set of skills</span>
        <h1>Skills And Experience</h1>
      </div>
      <div

        className="select"
      >
        <button
          onClick={() => setActive(1)}
          className={active === 1 ? "active" : ""}>Skills</button>
        <button
          onClick={() => setActive(2)}
          className={active === 2 ? "active" : ""}
        >Experience</button>
      </div>
      <motion.divI
        initial={{x: [0, 0],opacity: 0}}
        whileInView={{x: [-250, 0], opacity: 1}}
        transition={{ duration: .7 }}
        className="skills"
      >
        {active === 1 && icons.map((icon, index) => {
          return (
            <div data-tooltip={icon.name}>
            <div key={index} className="tools" >
              {icon.icon}
            </div>
            </div>
          )
        })}
      </motion.divI>
      <motion.div
        initial={{x: [0, 0], opacity: 0}}
        whileInView={{x: [250, 0], opacity: 1}}
        transition={{ duration: .7 }}
        className="experiences"
      >
        {active === 2 && experiences.map(experience => {
          return (
            <>
            <div className="experience" key={experience.id}>
              <span>{experience.year}</span>
              <div className="position">
                <h3>{experience.position}</h3>
                <p>{experience.company}</p>
              </div>
              <br/>
            </div>
            <div id="descRow" className="row">
              <div className="desc">
                <p>{experience.desc}</p>
              </div>
              </div>
            </>
          )
        })}
        </motion.div>

        

    </div>
  )
}

export default Skills