import React, { useRef, useState, useEffect } from 'react';
import "./Contact.scss";
import { contacts } from '../../../Data';
import { socialIcons } from '../../../Data';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import {FiChevronUp,FiMail} from 'react-icons/fi';
import {FaPaperPlane } from "react-icons/fa";

export const Contact = () => {
  const [scroll, setScroll] = useState(false);
  const [toggle, setToggle] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0nofulo', 'template_8cuwjps', form.current, 'UwLf3wbDIZDco1n9J')
      .then((result) => {
          console.log(result.text);
          e.target.reset();
          sentMessage();
      }, (error) => {
          console.log(error.text);
      });
  };

  const sentMessage = (e) => {
  const msg = document.getElementById('sentMessage');
  msg.innerHTML = "Thanks for the email!🎉🎉🎉"+ "<br/>" +"Please allow some time for me to read it and get back to you as soon as possible."+ "<br/>" +"Have a great day 🙏"
  }
  useEffect(() => {
    window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 20)

    })
},[])
  return (
    <div className="container" id="contact">
      <div 
        className="title"
      >
            <span>How to Contact me</span>
            <h1><FiMail/></h1>
      </div>
      <div className="contact_form">
        <motion.div
          initial={{x: 0, opacity: 0}}
          whileInView={{ x: [-150,0], opacity: 1 }}
          transition={{duration: 1}}
          className='contact_left_container'>
          <h3>Let's Talk.</h3>
          <p className='contact_text'>Thanks for taking the time to have a look through my portfolio. I'd love to have a conversation about your goals and needs, in the web design and development space, feel free to reach out anytime, contact info below.<br></br><br></br> Kind regards, <br></br> David</p>
          {contacts.map(contact => {
            return (
              <div className='contact_left' key={contact.id}>
                <div className="icon">
                  {contact.icon}
                </div>
                <p>{ contact.infoText}</p>
              </div>
            )
          })}
          <div className="social_icons">
            {socialIcons.map((socialIcon, index) => {
            return (
              <div key={index} >
                {socialIcon}
              </div>
            )
          })}
          </div>
        </motion.div>
        <motion.div
          initial={{x: 0, opacity: 0}}
          whileInView={{ x: [150,0], opacity: 1 }}
          transition={{duration: 1}}
          className="contact_right"
        
        >
          <h3 className="sendMessage">Email Me Below </h3>
          
          <form ref={form} onSubmit={sendEmail}>
          <div className="row">
          <label>Name</label>
          </div>
          <div className="row">
          <input type="text" name="user_name" />
          </div>
          <div className="row">
          <label>Email</label>
          </div>
          <div className="row">
          <input type="email" name="user_email" />
          </div>
          <div className="row">
          <label>Message</label>
          </div>
          <div className="row">
          <textarea name="message" />
          </div>
          <div className="row">
          <span id="sentMessage"></span>
          </div>

          <input className="btn" type="submit" value="Send" />
    
          
          </form>
   
        </motion.div>

          
        
      </div>
    </div>
  )
}

export default Contact