import React, { useState, useEffect } from 'react';
import "./Navbar.scss";
import { animate, motion } from 'framer-motion';
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { navLinks } from '../../Data';

const WavingHand = () => {
  
    return (
      <motion.div
        style={{
          marginBottom: '-20px',
          marginRight: '-45px',
          paddingBottom: '20px',
          paddingRight: '45px',
          display: 'inline-block',
        }}
        animate={{ 
            rotate: 20,
        
        }}
        transition={{
          repeat: Infinity,
          repeatType: 'reverse',
          from: 0,
          duration: 1.2,
          ease: 'easeInOut',
          type: 'tween',
        }}
      >
        👋
      </motion.div>
    );
  };

const Navbar = () => {
    
    const [scroll, setScroll] = useState(false);
    const [toggle, setToggle] = useState(false)
    
    const menuVariants = {
        hidden: {
            scale: 0,
            transition: { staggerChildren: 0.07, delayChildren: 0.2 }
        },
        visible: {
            scale: 50,
            transition: { staggerChildren: 0.05, staggerDirection: -1 }
        }
    }
    const navLinkVariants = {
        hidden: {
            display: "none",
            opacity: 0
        },
        visible: {
            opacity: 1,
            y: 20,
            transition: {
                delay: 0
            }
        }
    }
    
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 20)

        })
    },[])
  return (
      <motion.div
          initial={{ y: 10 }}
          animate={{ y: -5 }}
          transition={{duration: 0.5}}
          className={scroll ? "header active" : "header"}>
          <div className="Nav_container">
              <div className="logo">
              <h3>
   <WavingHand />   Hello!
  </h3>
              </div>
              <ul
                      className="nav_links"
                   >
                  {navLinks.map((navlink,index) => {
                      return <li key={index}><a href={`#${navlink}`}>{navlink}</a></li>
                  })}
              </ul>

              <div className="menu" >
                  <HiMenuAlt4 onClick={() => {setToggle(true)}}/>
              </div>
              <motion.div className={scroll ? "closeMenu" : "closeMenu"}
                          variants={menuVariants}
                          initial="hidden" animate= {toggle ?"visible": "hidden"}
                          style={{background:'white'}}
                      >
              </motion.div>
              
          <motion.div
                  variants={navLinkVariants}
                  animate={toggle ? "visible" : "hidden"}
                  className="menuX"
              >
                 <div><HiX onClick={() => setToggle(false)}/></div>
                <div>
                  {navLinks.map((navlink,index) => {
                      return <li
                          key={index}><a
                         
                          href={`#${navlink}`} onClick={() => setToggle(false)}>{navlink}</a></li>
                  })}
                 </div>
              </motion.div>
          </div>
    </motion.div>
  )
}

export default Navbar