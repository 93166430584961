import React, {useCallback} from 'react'
import portfolio from "../../../assets/portfolio.jpg"
import "./Home.scss"
import { motion } from 'framer-motion'


const Home = () => {
  
  
  const moveVariants = {
    animation: {
      y: [0, -15],
      transition: {
        yoyo: Infinity,
        duration: 2,
        delay: 1
      }

    }
  }

  return (
    <motion.div className="container " id='home'
      initial={{ y: -15, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={
        {
          duration: 2,
          delay: 0.5
        }
      }
    >
      
      <motion.div
           initial={{  opacity: 0 }}
           animate={{  opacity: 1 }}
           exit={{opacity: 0}}
           transition={
             {
               duration: 3,
               delay: 0
             }
           }
      >
      </motion.div>
    
      <motion.div
          initial={{ x: 0, opacity: 0, rotate: -180 }}
          whileInView={{ x: [-250, 0], opacity: 1, rotate: 0 }}
          transition={{ duration: .6, delay: 0.1 }}
          className="home_image">
          <motion.img src={portfolio}
            whileHover={{ y: -48, x: -55 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
     
      <div className="profile_text">
        <br></br>
        <h3 className='name'>Hi, I'm David Vallejo </h3>
        <span className='job'> Web Designer & Web Developer</span>
        <span className='job'>Based in Florida.</span>
        <span className='text'> crafting <br/> innovative <br/> web solutions</span>
        <motion.a
          href="#about"
          whileHover={{ scale: 1.1 }}
          variants={moveVariants}
          animate="animation"
        >
          Learn more
        </motion.a>

      </div>
      <motion.div
           initial={{  opacity: 0 }}
           animate={{  opacity: 1 }}
           exit={{opacity: 0}}
           transition={
             {
               duration: 2,
               delay: 1
             }
           }
      >
  
      </motion.div>
    </motion.div>
  )
}

export default Home