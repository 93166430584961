import React from 'react'
import './UnderContainer.scss'
import { motion} from 'framer-motion';

const UnderContainer = () => {
  return (
    <motion.a
    initial={{ opacity: 0, y: -20}}
    whileInView={{
      opacity:
        1,
        y:0
    }}
    transition={{duration: 1.5}}
    className="underContainer" href="#home" >
 
    <div className="upButton">
          <h3>Fastest way to the top! 🚀 </h3>
         </div>
    </motion.a>
  )
}

export default UnderContainer